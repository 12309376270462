<template>
    <el-dialog v-dialog-drag :title="title" :width="width" :visible="visible" :close-on-click-modal="false"
        @close="handleClose">
        <el-form ref="editForm" :model="data" :rules="rules" :inline="true" label-width="80px">
            <el-row>
                <el-col :span="24" style="text-align:center;margin-bottom: 10px;">
                    <span style="color: red;" v-show="tips">{{ tips }}</span>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="18">
                    <el-form-item label="货号：" prop="ItemNo">
                        <el-input v-model="data.ItemNo" :disabled="!isNew" placeholder="请输入货号" style="width:150px;" />
                    </el-form-item>
                    <el-form-item label="商品价：" prop="Price">
                        <el-input v-model="data.Price" placeholder="请输入商品价" v-format.float style="width:150px;" />
                    </el-form-item>
                    <el-form-item label="采购价：" prop="Cost">
                        <el-input v-model="data.Cost" placeholder="请输入采购价" v-format.float style="width:150px;" />
                    </el-form-item>
                    <el-form-item label="供应商：">
                        <el-autocomplete placeholder="供应商" v-model="data.DefaultSupplierName" style="width:150px;"
                            :fetch-suggestions="loadSuppliers" />
                    </el-form-item>
                    <el-form-item label="颜色：">
                        <dynamic-tags style="width:450px;" v-model="colors" title="添加颜色" :exten="{ visable: false }"
                            @valueChange="colorsChange" />
                    </el-form-item>
                    <el-form-item label="尺码：">
                        <dynamic-tags style="width:450px;" v-model="sizes" title="添加尺码"
                            :exten="{ visable: true, title: '选择尺码' }" @valueChange="sizesChange" />
                    </el-form-item>
                </el-col>
                <el-col :span="6" style="text-align:center;">
                    <el-upload ref="uploadImage" class="avatar-uploader" accept="image/*" :action="uploadUrl"
                        :headers="headers" :show-file-list="false" :on-success="uploadImgSuccess"
                        :on-error="uploadImgError">
                        <img v-if="data.DefaultImage" :src="data.DefaultImage" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-col>
            </el-row>
            <el-form-item label="备注：">
                <el-input type="textarea" :rows="2" v-model="data.Memo" style="width:600px;"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button size="small" @click="handleClose">关闭</el-button>
            <el-button type="primary" size="small" :loading="loading" @click="handleSave">保存</el-button>
        </div>
    </el-dialog>
</template>
<script>
import { submit } from '@/api/base';
import { baseURL, successCode } from '@/config';
import DynamicTags from './Tags.vue'
export default {
    name: 'ProductEditSimple',
    components: {
        DynamicTags
    },
    data() {
        return {
            loading: false,
            visible: false,
            width: '800px',
            title: '新增货品',
            isNew: true,
            uploadUrl:
                baseURL + "/api/base/file/uploadImg?path=product",
            headers: {
                Authorization: "Bearer " + this.$store.getters["user/accessToken"],
            },
            data: {},
            suppliers: [],
            colors: [],
            sizes: [],
            rules: {
                ItemNo: [
                    { required: true, message: '请输入货号', trigger: 'blur' }
                ],
                Price: [
                    { required: true, message: '请输入商品价', trigger: 'blur' }
                ]
            },
            tips: ''
        }
    },
    methods: {
        async fetchSuppliers() {
            const { data } = await submit('/api/purchase/supplier/getList', {
                CurrentPage: 1,
                PageSize: 1000,
            });
            data.datas.forEach(element => {
                element.value = element.SupplierName
            });
            this.suppliers = data.datas || [];
        },
        createFilter(qs) {
            return (supplier) => {
                return supplier.value.toLowerCase().includes(qs.toLowerCase());
            };
        },
        async loadSuppliers(qs, cb) {
            const suppliers = this.suppliers;
            var results = qs ? suppliers.filter(this.createFilter(qs)) : suppliers;
            cb(results || []);
        },
        async initDialog(title, productID, addData = null) {
            const _this = this;

            _this.visible = true;

            if (title) {
                _this.title = title;
            }

            if (!productID) {
                _this.isNew = true;
                if (addData) {
                    _this.data.ItemNo = addData.ItemNo;
                    _this.data.Colors = addData.Colors;
                    _this.data.Sizes = addData.Sizes;
                }
            } else {
                _this.isNew = false;
                const { data } = await submit('/api/product/getInfo', {
                    productID: productID,
                    IncludeColorsAndSizes: true
                });
                _this.data = data;
            }
            if (addData && addData.tips && !_this.isNew) {
                _this.tips = addData.tips;
            }
            else {
                _this.tips = '';
            }
            if (_this.data.Colors) {
                _this.colors = _this.data.Colors.split(',')
            }

            if (_this.data.Sizes) {
                _this.sizes = _this.data.Sizes.split(',')
            }

            await _this.fetchSuppliers();
        },
        handleSave() {
            const _this = this;
            _this.$refs.editForm.validate(async (valid) => {
                if (!valid) return false;
                if (!_this.data.Colors && _this.data.Sizes) {
                    _this.$message({ type: 'error', message: '颜色不能为空，请配置' });
                    return false;
                }
                if (_this.data.Colors && !_this.data.Sizes) {
                    _this.$message({ type: 'error', message: '尺码不能为空，请配置' });
                    return false;
                }

                _this.data.UserPrice = _this.data.Price;
                const param = JSON.parse(JSON.stringify(_this.data))

                let result;
                _this.loading = true;
                if (_this.isNew) {
                    result = await submit('/api/product/doAdd', param).finally(() => {
                        _this.loading = false;
                    });
                } else {
                    result = await submit('/api/product/doEdit', param).finally(() => {
                        _this.loading = false;
                    });
                }
                _this.handleClose();
                _this.$message({ type: 'success', message: '保存成功' });
                _this.$emit('handleSearch');
            });
        },
        handleClose() {
            this.visible = false;
            this.data = {};
            this.colors = [];
            this.sizes = [];
            this.$refs.editForm.resetFields();
        },
        uploadImgSuccess(response) {
            if (successCode.includes(response.code)) {
                this.data.DefaultImage = response.data;
            } else {
                this.$message({
                    type: 'error',
                    message: response.msg
                });
            }
        },
        uploadImgError() {
            this.$message({
                type: 'error',
                message: '上传失败'
            });
        },
        colorsChange() {
            this.data.Colors = this.colors.join(',');
        },
        sizesChange() {
            this.sizes = this.sizes.sort(function (a, b) {
                if (a !== b) return a < b ? -1 : 1
                return 0;
            });
            this.data.Sizes = this.sizes.join(',');
        }
    }
}
</script>
<style scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;

    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar {
    width: 178px;
    height: 178px;
    display: block;
}
</style>