<template>
  <div>
    <el-input ref="saveTagInput" size="small" class="input-new-tag" v-if="inputVisible" v-model="inputValue"
      @blur="handleInputConfirm" @change="handleInputConfirm" />
    <el-button v-else class="button-new-tag" size="small" @click="showInput">+ {{ title }}</el-button>

    <el-popover ref="popover" v-if="exten.visable" placement="top-start" width="540" trigger="click">
      <el-radio-group v-model="sizeType" style="margin-bottom: 30px;margin-left: 10px;" @change="sizeTypeChange">
        <el-radio-button label="男鞋">男鞋</el-radio-button>
        <el-radio-button label="女鞋">女鞋</el-radio-button>
        <el-radio-button label="自定义">自定义</el-radio-button>
      </el-radio-group>
      <el-button style="float:right;margin-right:10px;" type="primary" @click="sliderChangeConfirm">确认</el-button>
      <div style="width:500px;margin-left:20px;">
        <el-slider range v-model="slider.value" :marks="slider.marks" :min="slider.min" :max="slider.max" />
      </div>
      <el-button slot="reference" class="button-new-tag" size="small">{{ exten.title }}</el-button>
    </el-popover>

    <br />
    <el-tag :key="tag" v-for="tag in value" closable :disable-transitions="false" @close="handleClose(tag)">
      {{ tag }}
    </el-tag>
  </div>
</template>
<script>
export default {
  name: 'DynamicTags',
  props: {
    value: {
      type: Array,
      require: true,
    },
    title: {
      type: String,
      require: true
    },
    exten: {
      type: Object,
      require: false,
      default: function () {
        return { visable: false }
      }
    }
  },
  data() {
    return {
      inputValue: '',
      inputVisible: false,
      sizeType: '自定义',
      slider: {
        show: false,
        value: [38, 45],
        min: 20,
        max: 50,
        marks: {
          20: '20码',
          25: '25码',
          30: '30码',
          35: '35码',
          40: '40码',
          45: '45码',
          50: {
            style: {
              width: '29.58px'
            },
            label: '50码'
          }
        }
      },
    };
  },
  methods: {
    sliderChangeConfirm() {
      const value = this.slider.value;
      const min = value[0];
      const max = value[1];
      for (let i = min; i <= max; i++) {
        if (!this.value.some(x => x == i)) {
          this.value.push(i);
        }
      }
      this.$emit('valueChange');
      this.$refs.popover.doClose();
    },
    sizeTypeChange() {
      switch (this.sizeType) {
        case "男鞋":
          this.slider.value = [39, 44];
          break;
        case "女鞋":
          this.slider.value = [35, 40];
          break;
        case "自定义":
          this.slider.value = [35, 45];
          break;
      }
    },
    handleClose(tag) {
      this.value.splice(this.value.indexOf(tag), 1);
      this.$emit('valueChange');
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        if (!this.value.some(x => x == inputValue)) {
          this.value.push(inputValue);
          this.$emit('valueChange');
        } else {
          this.$message({ type: 'error', message: '尺码【' + inputValue + '】已存在' });
        }
      }
      this.inputVisible = false;
      this.inputValue = '';
    },
  }
}
</script>
<style scoped>
.el-tag {
  margin-left: 0px !important;
  margin-right: 5px !important;
}

.button-new-tag {
  margin-left: 0;
  margin-right: 5px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-right: 5px;
}
</style>